<template>
  <div>
    <h4 class="text-center mt-5">loading...</h4>
  </div>
</template>

<script>
export default {
  mounted() {
    this.downloadFile();
  },
  methods: {
    downloadFile() {
      window.open("https://api.cabinet.costaction.com/api/lastfiles", '_blank').focus();
      window.location.href = "/dashboard";
    }
  }
};
</script>
